import Tilt from "../../../../portfolio/remote-touchscreen-control/tilt.png";
import TiltHaptic from "../../../../portfolio/remote-touchscreen-control/tilt-haptic.png";
import Touchscreen from "../../../../portfolio/remote-touchscreen-control/touchscreen.png";
import Fitts from "../../../../portfolio/remote-touchscreen-control/fitts.png";
import BubbleCursor from "../../../../portfolio/remote-touchscreen-control/bubble-cursor.png";
import System from "../../../../portfolio/remote-touchscreen-control/system.png";
import Phone from "../../../../portfolio/remote-touchscreen-control/phone.png";
import Interface from "../../../../portfolio/remote-touchscreen-control/interface.png";
import Results from "../../../../portfolio/remote-touchscreen-control/results.png";
import * as React from 'react';
export default {
  Tilt,
  TiltHaptic,
  Touchscreen,
  Fitts,
  BubbleCursor,
  System,
  Phone,
  Interface,
  Results,
  React
};