import DesignFunnel1 from "../../../../portfolio/gu-xplore/design-funnel-1.png";
import DesignFunnel2 from "../../../../portfolio/gu-xplore/design-funnel-2.png";
import DesignFunnel3 from "../../../../portfolio/gu-xplore/design-funnel-3.png";
import UserPersonas from "../../../../portfolio/gu-xplore/user-personas.png";
import Storyboards from "../../../../portfolio/gu-xplore/storyboards.png";
import Prototype1 from "../../../../portfolio/gu-xplore/prototype1.png";
import Prototype2 from "../../../../portfolio/gu-xplore/prototype2.png";
import Wireframe1 from "../../../../portfolio/gu-xplore/wireframe1.png";
import * as React from 'react';
export default {
  DesignFunnel1,
  DesignFunnel2,
  DesignFunnel3,
  UserPersonas,
  Storyboards,
  Prototype1,
  Prototype2,
  Wireframe1,
  React
};