exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-mdx-slug-js": () => import("./../../../src/pages/portfolio/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-portfolio-mdx-slug-js" */),
  "component---src-templates-blog-blog-list-js": () => import("./../../../src/templates/blog/blog-list.js" /* webpackChunkName: "component---src-templates-blog-blog-list-js" */),
  "component---src-templates-blog-blog-post-js": () => import("./../../../src/templates/blog/blog-post.js" /* webpackChunkName: "component---src-templates-blog-blog-post-js" */),
  "component---src-templates-blog-tags-js": () => import("./../../../src/templates/blog/tags.js" /* webpackChunkName: "component---src-templates-blog-tags-js" */),
  "component---src-templates-blog-tags-list-js": () => import("./../../../src/templates/blog/tags-list.js" /* webpackChunkName: "component---src-templates-blog-tags-list-js" */),
  "component---src-templates-portfolio-tags-js": () => import("./../../../src/templates/portfolio/tags.js" /* webpackChunkName: "component---src-templates-portfolio-tags-js" */),
  "component---src-templates-portfolio-tags-list-js": () => import("./../../../src/templates/portfolio/tags-list.js" /* webpackChunkName: "component---src-templates-portfolio-tags-list-js" */)
}

