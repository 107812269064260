module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-highlight-code","id":"a6a1897c-9428-555d-9922-6dc8b8f9309a","name":"gatsby-remark-highlight-code","version":"3.3.0","modulePath":"/opt/build/repo/node_modules/gatsby-remark-highlight-code/index.js","pluginOptions":{"plugins":[],"theme":"solarized-light"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-autolink-headers","id":"84411983-3572-54e6-8782-dedc5abaf1a7","name":"gatsby-remark-autolink-headers","version":"5.25.0","modulePath":"/opt/build/repo/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
