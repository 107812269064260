import DeskResearch from "../../../../portfolio/good-foodie/desk-research.png";
import AffinityDiagram from "../../../../portfolio/good-foodie/affinity-diagram.png";
import Crazy8 from "../../../../portfolio/good-foodie/crazy-8.png";
import FocusGroup from "../../../../portfolio/good-foodie/focus-group.png";
import Wireframe from "../../../../portfolio/good-foodie/wireframe.png";
import Fridge from "../../../../portfolio/good-foodie/fridge.png";
import HiFi1 from "../../../../portfolio/good-foodie/hifi-1.png";
import HiFi2 from "../../../../portfolio/good-foodie/hifi-2.png";
import HiFi3 from "../../../../portfolio/good-foodie/hifi-3.png";
import HiFi4 from "../../../../portfolio/good-foodie/hifi-4.png";
import HiFi5 from "../../../../portfolio/good-foodie/hifi-5.png";
import UserTest from "../../../../portfolio/good-foodie/user-test.png";
import * as React from 'react';
export default {
  DeskResearch,
  AffinityDiagram,
  Crazy8,
  FocusGroup,
  Wireframe,
  Fridge,
  HiFi1,
  HiFi2,
  HiFi3,
  HiFi4,
  HiFi5,
  UserTest,
  React
};