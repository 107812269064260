import Initialisation from "../../../../portfolio/particle-based-displays/initialisation.png";
import Detection from "../../../../portfolio/particle-based-displays/detection.png";
import Matching from "../../../../portfolio/particle-based-displays/matching.png";
import PotentialField from "../../../../portfolio/particle-based-displays/potential-field.png";
import Trajectory from "../../../../portfolio/particle-based-displays/trajectory.png";
import * as React from 'react';
export default {
  Initialisation,
  Detection,
  Matching,
  PotentialField,
  Trajectory,
  React
};